import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VImg } from 'vuetify/lib/components/VImg';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.userList,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{class:item.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.avatar ? '' : 'primary',"size":"32"}},[(item.avatar)?_c(VImg,{attrs:{"src":require(("@/assets/images/avatars/" + (item.avatar)))}}):_c('span',[_vm._v(_vm._s(item.full_name.slice(0,2).toUpperCase()))])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.full_name))]),_c('small',[_vm._v(_vm._s(item.post))])])],1)]}},{key:"item.salary",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("$" + (item.salary)))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticClass:"v-chip-light-bg",class:((_vm.statusColor[_vm.status[item.status]]) + "--text"),attrs:{"small":"","color":_vm.statusColor[_vm.status[item.status]]}},[_vm._v(" "+_vm._s(_vm.status[item.status])+" ")])]}}],null,true)}),_c(VCardText,{staticClass:"pt-2"},[_c(VRow,[_c(VCol,{attrs:{"lg":"2","cols":"3"}},[_c(VTextField,{attrs:{"value":_vm.itemsPerPage,"label":"Rows per page:","type":"number","min":"-1","max":"15","hide-details":""},on:{"input":function($event){_vm.itemsPerPage = parseInt($event, 10)}}})],1),_c(VCol,{staticClass:"d-flex justify-end",attrs:{"lg":"10","cols":"9"}},[_c(VPagination,{attrs:{"total-visible":"6","length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }