import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VEditDialog } from 'vuetify/lib/components/VDataTable';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.userList},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VCardText,[_vm._v("Try editing "),_c('code',{staticClass:"mx-1"},[_vm._v("name")]),_vm._v(" or "),_c('code',{staticClass:"mx-1"},[_vm._v("salary")]),_vm._v(" column")])]},proxy:true},{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [_c(VEditDialog,{attrs:{"return-value":item.full_name},on:{"update:returnValue":function($event){return _vm.$set(item, "full_name", $event)},"update:return-value":function($event){return _vm.$set(item, "full_name", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c(VTextField,{attrs:{"rules":[_vm.max25chars],"label":"Edit","single-line":"","counter":""},model:{value:(item.full_name),callback:function ($$v) {_vm.$set(item, "full_name", $$v)},expression:"item.full_name"}})]},proxy:true}],null,true)},[_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{class:item.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.avatar ? '' : 'primary',"size":"32"}},[(item.avatar)?_c(VImg,{attrs:{"src":require(("@/assets/images/avatars/" + (item.avatar)))}}):_c('span',[_vm._v(_vm._s(item.full_name.slice(0,2).toUpperCase()))])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.full_name))]),_c('small',[_vm._v(_vm._s(item.post))])])],1)])]}},{key:"item.salary",fn:function(ref){
var item = ref.item;
return [_c(VEditDialog,{attrs:{"return-value":item.salary,"large":"","persistent":""},on:{"update:returnValue":function($event){return _vm.$set(item, "salary", $event)},"update:return-value":function($event){return _vm.$set(item, "salary", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 title"},[_vm._v(" Update Salary ")]),_c(VTextField,{attrs:{"rules":[_vm.max25chars],"label":"Edit","single-line":"","counter":"","autofocus":"","type":"number","prefix":"$"},model:{value:(item.salary),callback:function ($$v) {_vm.$set(item, "salary", $$v)},expression:"item.salary"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(("$" + (item.salary)))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticClass:"v-chip-light-bg",class:((_vm.statusColor[_vm.status[item.status]]) + "--text"),attrs:{"small":"","color":_vm.statusColor[_vm.status[item.status]]}},[_vm._v(" "+_vm._s(_vm.status[item.status])+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }