import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","offset-md":"8","md":"4"}},[_c(VTextField,{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Search","single-line":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c(VDataTable,{staticClass:"table-kitchen-sink",attrs:{"headers":_vm.headers,"items":_vm.productList,"search":_vm.search,"items-per-page":5},scopedSlots:_vm._u([{key:"item.product.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c(VImg,{attrs:{"src":item.product.image,"height":"40","width":"40"}})],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block font-weight-semibold text-truncate text--primary"},[_vm._v(_vm._s(item.product.name))]),_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(item.product.brand))])])])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.date))])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._l((_vm.categoryIconFilter(item.product.category)),function(category,index){return _c(VAvatar,{key:index,staticClass:"v-avatar-light-bg",class:((category.color) + "--text"),attrs:{"size":"26","color":category.color}},[_c(VIcon,{staticClass:"rounded-0",attrs:{"size":"20","color":category.color}},[_vm._v(" "+_vm._s(category.icon)+" ")])],1)}),_c('span',{staticClass:"ms-1 text-no-wrap"},[_vm._v(_vm._s(item.product.category))])],2)]}},{key:"item.buyer.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text",attrs:{"size":"1.875rem","color":"primary"}},[(item.buyer.avatar)?_c(VImg,{attrs:{"src":item.buyer.avatar}}):_c('span',[_vm._v(_vm._s(item.buyer.name.slice(0,2).toUpperCase()))])],1),_c('span',{staticClass:"text-no-wrap font-weight-semibold text--primary ms-2"},[_vm._v(_vm._s(item.buyer.name))])],1)]}},{key:"item.payment",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"primary--text font-weight-medium"},[_vm._v("$"+_vm._s(item.payment.paid_amount))]),(item.payment.paid_amount !== item.payment.total)?_c('span',[_vm._v("/"+_vm._s(item.payment.total))]):_vm._e()]),_c('span',{staticClass:"text-xs text-no-wrap"},[_vm._v(_vm._s(item.payment.received_payment_status))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticClass:"v-chip-light-bg",class:((_vm.statusColor[item.payment.status]) + "--text"),attrs:{"color":_vm.statusColor[item.payment.status],"small":""}},[_vm._v(" "+_vm._s(item.payment.status)+" ")])]}},{key:"item.delete",fn:function(){return [_c('a',[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiDeleteOutline))])],1)]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }